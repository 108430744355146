import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'whatwg-fetch';

/* polyfill for matches */
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

/* polyfill for closest */
if (!Element.prototype.closest) {
  Element.prototype.closest = function closest(selector) {
    let ancestor = this;
    if (!document.documentElement.contains(this)) {
      return null;
    }

    do {
      if (ancestor.matches(selector)) {
        return ancestor;
      }
      ancestor = ancestor.parentElement;
    } while (ancestor !== null);

    return null;
  };
}
